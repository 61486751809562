<template>
  <div class="index">
    
    <div class="nav-wallet-banner">
      <div class="nav-wallet-container">
        <ul class="nav-wallet-content">
          <li>
            <h2><i class="icon-wallet icon-wallet-total"></i>存借总量：</h2>
            <p>$0</p>
          </li>
          <li>
            <h2><i class="icon-wallet icon-wallet-income"></i>TVL：</h2>
            <p>$0</p>
          </li>
          <li>
            <h2><i class="icon-wallet icon-wallet-price"></i>ROO当前价格：</h2>
            <p>$0</p>
          </li>
          <li>
            <h2><i class="icon-wallet icon-wallet-tongji"></i>ROO流通量：</h2>
            <p>-</p>
          </li>
        </ul>
      </div>
    </div>
    <!---->
    <div class="userTip-container">
      <div class="content">
        <h2>
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAaCAYAAADWm14/AAAAAXNSR0IArs4c6QAAAmpJREFUSA21VktrFEEQ/mp181CiWbyseZggIR4kSEBy8Ogx/gVR9KQgvg6KhngyG5OAOXjwIIJecxHBf+DJo3jSeFBRUTxsRFYhE6as6Qk9j+7pmU7Yhobq6urqb7a/r2oJOxy8NDqELX4px6dA9Bh3vtwkIvZNV/M9oOMDfgLmkzJ7EYbX0TpyXu95GOQRq0N5YfgMGK+0Qxn0A/X9k3T7/Z+s373y/gV47XiPpHxopuUmgs490+/2eAPA+u9r8vWTBWmvcuto0Z71iBcAXh5vAjyvM/UPAo1xvZS9HoSbqylHqekFAJvBA/n6AZV1bx9w6TVwWebQdOoinuXWyGzK4TQrA+D7IzMgnNPZGmPAvgZEggLghHYrg3l1mytZv2VVCQAzk1z+SL6+mmoijkRcqTAqAVAaZ56pkC8VwvMxZ1Iui1kKgJeODUixWbScdbsirkScKRmlALDVEdaLxncyhDOKO46zTgBK04xKb2m9I+KMcEdxyBoAOAHEmhZt72ZE3HH0iUIAsZa5WM/tz8DftryONMDvb90QhUOKS5Yoq6yUhtc33jlKbpwqqoR9MtufLKlzLqIVmvt6K+cteAJ3vU9y/Nuodnl0Qrhk6xPGExj1Prlul5a9TxgAMvW+7Mqza8CVN8CB4bLI7X2zT2QAGPXelfbQBDB2Cjgol0+cdkVm93J9QgPwrve1PUnitJ147VauT2gA3vXeR4YGlKRPKBkqjQadD94l10eGeRCEZzT37UIMYGH0Ijh8mo/p8jpErb8ZPwGF9S5fZqYnqQwyYgCH68/ln80LmYEZ2QUP4af8lbpBdz/++g8+Js+XhjTSYAAAAABJRU5ErkJggg=="
            class="icon-tips"
          />温馨提示：出于安全考虑，MDX的抵押率为零，不可用于抵押。BAGS不可用于存借，即将下线。
        </h2>
      </div>
    </div>
    <div class="userInfo-container">
      <div class="title">账户状态</div>
      <div class="userInfo-content">
        <div class="wallet-balance">
          <dl>
            <dd>存款金额：<span> $0 </span></dd>
            <dd>借款金额：<span> $0 </span></dd>
          </dl>
          <!---->
        </div>
        <div class="wallet-risk">
          <div class="wallet-risk-filed" v-if="!isMobile">借款额度使用率:</div>
          <div class="wallet-risk-progress">
            <div class="new-custom-progress">
              <div class="new-custom-progress-bar">
                <div class="new-custom-progress__outer">
                  <div
                    class="new-custom-progress__inner new-custom-progress__inner-lv1"
                    style="width: 0%"
                  ></div>
                </div>
                <div
                  class="new-custom-progress__text new-custom-progress__text-lv1"
                  style="background-color: rgb(235, 238, 245); left: 0%"
                >
                  0%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-container">
      <div class="userList-container">
        <div class="ant-tabs ant-tabs-top">
          <div class="ant-tabs-nav">
            <div
              class="ant-tabs-nav-wrap ant-tabs-nav-wrap-userTab"
              :class="isMobile ? 'ant-tabs-nav-wrap-mobile' : ''"
            >
              <div class="ant-tabs-nav-list">
                <div
                  class="ant-tabs-tab"
                  @click="curTab = 0"
                  :class="curTab == 0 ? 'ant-tabs-tab-active' : ''"
                >
                  <div
                    id="rc-tabs-0-tab-1"
                    tabindex="0"
                    class="ant-tabs-tab-btn"
                  >
                    我的存款
                  </div>
                </div>
                <div
                  class="ant-tabs-tab"
                  @click="curTab = 1"
                  :class="curTab == 1 ? 'ant-tabs-tab-active' : ''"
                >
                  <div id="rc-tabs-0-tab-2" class="ant-tabs-tab-btn">
                    我的借款
                  </div>
                </div>
                <div
                  class="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                  style="left: 0px; width: 56px"
                ></div>
              </div>
            </div>
            <div class="ant-tabs-nav-operations ant-tabs-nav-operations-hidden">
              <button
                id="rc-tabs-0-more"
                type="button"
                tabindex="-1"
                aria-hidden="true"
                aria-haspopup="listbox"
                aria-controls="rc-tabs-0-more-popup"
                aria-expanded="false"
                class="ant-tabs-nav-more"
                style="visibility: hidden; order: 1"
              >
                <span aria-label="ellipsis" class="anticon anticon-ellipsis"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="ellipsis"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"
                    ></path></svg
                ></span>
              </button>
            </div>
          </div>
          <div class="ant-tabs-content-holder">
            <div
              class="ant-tabs-content ant-tabs-content-top"
              v-if="curTab == 0"
            >
              <div
                id="rc-tabs-0-panel-1"
                tabindex="0"
                aria-hidden="false"
                aria-labelledby="rc-tabs-0-tab-1"
                class="ant-tabs-tabpane ant-tabs-tabpane-active"
              >
                <div class="userTable">
                  <div class="warning-tip">
                    <div>
                      <span class="icon">!</span
                      >重要提示：存款所得到的lToken（lHT、lUSDT等）为重要的存款凭证，请不要轻易转移给其他人！<!---->
                    </div>
                    <img
                      :src="require('../assets/img/bi/closeBlack.a968c3b4.svg')"
                    />
                  </div>
                  <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                      <div class="ant-spin-container">
                        <div class="ant-table">
                          <div class="ant-table-container">
                            <div class="ant-table-content">
                              <table
                                style="table-layout: auto"
                                v-if="!isMobile"
                              >
                                <thead class="ant-table-thead">
                                  <tr>
                                    <th class="ant-table-cell">资产</th>
                                    <th class="ant-table-cell">
                                      存款APY
                                      <span class="ant-table-cell"></span>
                                    </th>
                                    <th class="ant-table-cell">
                                      挖矿APY
                                      <span class="ant-table-cell"></span>
                                    </th>
                                    <th class="ant-table-cell">已存</th>
                                    <th class="ant-table-cell">用作抵押</th>
                                    <th class="ant-table-cell">操作</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colspan="6">
                                      <p class="t-no-data"><i></i></p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="my-ccount-info" v-else>
                                <p class="t-no-data"><i></i></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="rc-tabs-0-panel-2"
                tabindex="-1"
                aria-hidden="true"
                aria-labelledby="rc-tabs-0-tab-2"
                class="ant-tabs-tabpane"
                style="display: none"
              ></div>
            </div>
            <div
              class="ant-tabs-content ant-tabs-content-top"
              v-if="curTab == 1"
            >
              <div
                id="rc-tabs-1-panel-2"
                role="tabpanel"
                tabindex="0"
                aria-hidden="false"
                aria-labelledby="rc-tabs-0-tab-2"
                class="ant-tabs-tabpane ant-tabs-tabpane-active"
              >
                <div class="userTable">
                  <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                      <div class="ant-spin-container">
                        <div class="ant-table">
                          <div class="ant-table-container">
                            <div class="ant-table-content">
                              <!---->
                              <div class="my-ccount-info">
                                <p class="t-no-data"><i></i></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-dialog__wrapper" style="display: none">
        <div
          aria-modal="true"
          aria-label="开启抵押"
          class="el-dialog ant-modal mortgage-modal"
          style="margin-top: 15vh; width: 30%"
        >
          <div class="el-dialog__header">
            <span class="el-dialog__title">开启抵押</span
            ><button
              type="button"
              aria-label="Close"
              class="el-dialog__headerbtn"
            >
              <i class="el-dialog__close el-icon el-icon-close"></i>
            </button>
          </div>
          <!----><!---->
        </div>
      </div>
      <div class="el-dialog__wrapper" style="display: none">
        <div
          aria-modal="true"
          aria-label="取消抵押"
          class="el-dialog ant-modal mortgage-modal"
          style="margin-top: 15vh; width: 30%"
        >
          <div class="el-dialog__header">
            <span class="el-dialog__title">取消抵押</span
            ><button
              type="button"
              aria-label="Close"
              class="el-dialog__headerbtn"
            >
              <i class="el-dialog__close el-icon el-icon-close"></i>
            </button>
          </div>
          <!----><!---->
        </div>
      </div>
    </div>
    <div class="infoList-container">
      <div class="title">单币市场</div>
      <div class="ant-table-wrapper">
        <div class="ant-spin-nested-loading">
          <div class="ant-spin-container">
            <div
              class="ant-table ant-table-fixed-column ant-table-scroll-horizontal ant-table-has-fix-left ant-table-has-fix-right"
            >
              <div class="ant-table-container">
                <div class="ant-table-content" style="overflow: auto hidden">
                  <table v-if="!isMobile">
                    <thead class="ant-table-thead">
                      <tr>
                        <th
                          title="Asset"
                          class="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last ant-table-cell-ellipsis"
                          style="position: sticky; left: 0px; z-index: 100"
                        >
                          <span class="ant-table-cell-content">资产</span>
                        </th>
                        <th class="ant-table-cell">
                          存款APY
                          <!-- <i
                            class="el-tooltip el-icon-question"
                            aria-describedby="el-tooltip-7291"
                            tabindex="0"
                          ></i> -->
                        </th>
                        <th class="ant-table-cell">
                          借款成本
                          <!-- <i
                            class="el-tooltip el-icon-question"
                            aria-describedby="el-tooltip-8527"
                            tabindex="0"
                          ></i> -->
                        </th>
                        <th
                          class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                        >
                          <span class="ant-table-cell-content"> 存借总额 </span>
                        </th>
                        <th
                          class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                        >
                          <span class="ant-table-cell-content">剩余可借 </span>
                        </th>
                        <th
                          class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                        >
                          <span class="ant-table-cell-content"
                            >我的钱包余额</span
                          >
                        </th>
                        <!---->
                        <th
                          title="Operation"
                          class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                        >
                          <span class="ant-table-cell-content">操作</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                      <tr class="el-loading-parent--relative">
                        <td colspan="8" style="text-align: center">
                          加载中...
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div data-v-deaf73aa="" class="order-info" v-else></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="infoList-container">
      <div class="title">
        LP市场
        <span style="color: rgb(254, 175, 13); font-size: 10px">
          存入LP会每10分钟进行自动复投
        </span>
      </div>
      <div class="ant-table-wrapper">
        <div class="ant-spin-nested-loading">
          <div class="ant-spin-container">
            <div
              class="ant-table ant-table-fixed-column ant-table-scroll-horizontal ant-table-has-fix-left ant-table-has-fix-right"
            >
              <div class="ant-table-container">
                <div class="ant-table-content" style="overflow: auto hidden">
                  <table v-if="!isMobile">
                    <thead class="ant-table-thead">
                      <tr>
                        <th
                          title="Asset"
                          class="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last ant-table-cell-ellipsis"
                          style="position: sticky; left: 0px; z-index: 100"
                        >
                          <span class="ant-table-cell-content">资产</span>
                        </th>
                        <th class="ant-table-cell">
                          存款APY
                          <!-- <i
                            class="el-tooltip el-icon-question"
                            aria-describedby="el-tooltip-1586"
                            tabindex="0"
                          ></i> -->
                        </th>
                        <th
                          class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                        >
                          <span class="ant-table-cell-content"> 存款总额 </span>
                        </th>
                        <th
                          class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                        >
                          <span class="ant-table-cell-content"
                            >我的钱包余额</span
                          >
                        </th>
                        <!---->
                        <th
                          title="Operation"
                          class="ant-table-cell ant-table-cell-ellipsis table-wallet"
                        >
                          <span class="ant-table-cell-content">操作</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                      <tr class="el-loading-parent--relative">
                        <td colspan="8" style="text-align: center">
                          加载中...
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div data-v-deaf73aa="" class="order-info" v-else></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="el-dialog__wrapper" style="display: none">
      <div
        aria-modal="true"
        aria-label="dialog"
        class="el-dialog ant-modal"
        style="margin-top: 0px; width: 600px"
      >
        <div class="el-dialog__header">
          <div class="el-dialog__header-slot">
            <img />
          </div>
          <button type="button" aria-label="Close" class="el-dialog__headerbtn">
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <!----><!---->
      </div>
    </div>
    <div class="el-dialog__wrapper" style="display: none">
      <div
        aria-modal="true"
        aria-label="dialog"
        class="el-dialog borrow-modal ant-modal"
        style="margin-top: 0px; width: 600px"
      >
        <div class="el-dialog__header">
          <div class="el-dialog__header-slot">
            <img />
          </div>
          <button type="button" aria-label="Close" class="el-dialog__headerbtn">
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <!----><!---->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curTab: 0,
      isMobile: false,
    };
  },
  mounted() {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
};
</script>

<style lang="scss">
.schart-box {
  margin: 30px auto;
  width: 1000px;
  height: 1200px;
  text-align: center;
}
.schart {
  width: 600px;
  height: 1200px;
}
.content-title {
  clear: both;
  font-weight: 400;
  line-height: 50px;
  margin: 10px 0;
  font-size: 22px;
  color: #1f2f3d;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.list {
  margin: 20px auto;
  width: 100%;
  position: relative;
  height: 500px;
  overflow-y: scroll;
}
.list .rowup {
  -webkit-animation: 200s rowup linear infinite normal;
  animation: 200s rowup linear infinite normal;
  position: relative;
}
.index-wrap {
  .paiming {
    .bg-blue {
      background-color: #2e96dd;
    }
    .bg-white {
      color: #2e96dd;
    }
  }
  .cu-list {
    .cu-item {
      height: auto;
      padding: 20px 0px;

      .title {
        width: 100%;
        padding: 0px 30px;
      }

      .item-middle {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0px 30px 20px;
      }
    }
  }
}
.charts-box {
  width: 100%;
  height: 700px;
}

@-webkit-keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, var(--height), 0);
    transform: translate3d(0, var(--height), 0);
  }
}
@keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, var(--height), 0);
    transform: translate3d(0, var(--height), 0);
  }
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
  text-align: center;
}
.margin-bottom {
  margin-bottom: 20px;
}
.flex-sub {
  flex: 1;
}
</style>
<style scoped>
.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  span-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  span-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}

.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  span-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}
</style>

<style>
.d-tabs {
  width: 1200px;
  min-height: 630px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.d-tabs .d-tabs-head {
  overflow: hidden;
  height: 40px;
}

.d-tabs .inline-item {
  background: #282d4f;
  width: 100%;
  font-size: 0;
}

.d-tabs .inline-item .tab-nav-item {
  letter-spacing: 1px;
  display: inline-block;
  padding: 0 10px;
  color: #fff;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  transition: all 0.3s;
  margin: 7px 10px;
  position: relative;
  border-radius: 4px;
}

.d-tabs .inline-item .tab-nav-item:hover {
  color: #ec002c;
  span-decoration: underline;
  cursor: pointer;
}

.d-tabs .inline-item .active {
  background-image: linear-gradient(-180deg, #d4d4d4, #fff);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: #fff;
  color: #282d4f;
}

.d-tabs .inline-item .active:hover {
  color: #282d4f;
  span-decoration: none;
}

.d-tabs .tab-nav {
  background: #282d4f;
  width: 100%;
  font-size: 0;
}

.d-tabs .tab-nav .tab-nav-item {
  letter-spacing: 1px;
  display: inline-block;
  padding: 0 32px;
  color: #fff;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  font-weight: 700;
  transition: all 0.3s;
  position: relative;
}

.d-tabs .tab-nav .tab-nav-item:hover {
  background-color: #7e5acf;
  cursor: pointer;
}

.d-tabs .tab-nav .active {
  background: #fff;
  color: #282d4f;
}

.d-tabs .tab-nav .active:hover {
  background-color: #fff;
  color: #282d4f;
  cursor: inherit;
}

.d-tabs .both-side {
  background: #282d4f;
  width: 100%;
  font-size: 0;
}

.d-tabs .both-side .tab-nav-item {
  letter-spacing: 1px;
  display: inline-block;
  position: relative;
  margin: 0 32px;
  color: #ec002c;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  font-weight: 700;
}

.d-tabs .both-side .tab-nav-item:before {
  content: "";
  height: 4px;
  width: 4px;
  background: #ec002c;
  position: absolute;
  left: -10px;
  top: 17px;
}

.d-tabs .both-side .tab-nav-item:after {
  content: "";
  height: 4px;
  width: 4px;
  background: #ec002c;
  position: absolute;
  right: -10px;
  top: 17px;
}

.d-tabs .show-tab {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
}

.d-tabs .show-tab .tav-rate {
  visibility: visible;
  opacity: 1;
}

.t-main {
  background: #bf001a;
  height: 38px;
  line-height: 38px;
  width: 1140px;
  margin: 0 auto;
  padding-left: 60px;
  color: #fff;
  font-size: 14px;
  position: relative;
}

.t-main .horn {
  position: absolute;
  left: 20px;
  top: 11px;
}

.t-main .r-img {
  position: absolute;
  right: 288px;
  top: 0;
}

.t-main marquee {
  cursor: pointer;
  width: 1120px;
}
</style>